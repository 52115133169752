import React from "react";
import "./getstart.css";

const GetStart = () => {
  return (
    <section className="g-wrapper">
      <div className="padding innerWidth g-container">
        <div className="flexColCenter inner-container">
          <span className="primaryText animated-text">
            Get Started With Techmetrica Software
          </span>
          <span className="secondaryText animated-text">
            We Execute Our Ideas From The Start To Finish.
          </span>
          <button className="button animated-button">
            <a href="mailto:varunyadav336@gmail.com">Get Started</a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default GetStart;
