import React from 'react';
import { Container, Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import './navbar.css';

function BasicNavbar() {
  return (
    <Navbar expand="lg" sticky="top" className="navbar bg-primary">
      <Container>
        <Navbar.Brand href="#home" className="text-white">TechMetrica</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#home" className="text-white">Home</Nav.Link>
            <Nav.Link href="#about" className="text-white">About Us</Nav.Link>
            <Nav.Link href="#services" className="text-white">Services</Nav.Link>
            <Nav.Link href="#contact" className="text-white">Contact Us</Nav.Link>
            <NavDropdown title="Products" id="basic-nav-dropdown" className="text-white">
              <NavDropdown.Item href="#action/3.1" className="dropdown-item">Eduok School Management</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2" className="dropdown-item">Eduok College Management</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3" className="dropdown-item">Office Management Software</NavDropdown.Item>
            </NavDropdown>
            <Button variant="light" className="get-in-touch-button">Get in Touch</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicNavbar;
