import React, { useState } from 'react';
import './FAQ.css'; // Import the CSS file

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setOpenIndex(index);
  };

  const handleMouseLeave = () => {
    setOpenIndex(null);
  };

  return (
    <div className="faq-container">
      <div className="faqcontainer">
        <div className="faq-layout">
          <div className="faq-support">
            <h4 className="faq-subtitle">Support</h4>
            <h3 className="faq-title">Frequently Asked Questions</h3>
            <p className="faq-description">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium!
            </p>
          </div>
          <div className="faq-questions">
            <div className="faq-accordion">
              <div
                className={`accordion-item ${openIndex === 0 ? 'open' : ''}`}
                onMouseEnter={() => handleMouseEnter(0)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="accordion-header">
                  <span className="accordion-title">Why should I choose Techmetrica?</span>
                  <span className="accordion-icon">{openIndex === 0 ? '-' : '+'}</span>
                </div>
                <div className={`accordion-body ${openIndex === 0 ? 'show' : ''}`}>
                  The Techmetrica Shop is built right into your account dashboard and is accessible immediately after signing up.
                </div>
              </div>
              <div
                className={`accordion-item ${openIndex === 1 ? 'open' : ''}`}
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="accordion-header">
                  <span className="accordion-title">Do I need to change banks?</span>
                  <span className="accordion-icon">{openIndex === 1 ? '-' : '+'}</span>
                </div>
                <div className={`accordion-body ${openIndex === 1 ? 'show' : ''}`}>
                  You do not need to change banks. Techmetrica integrates with your existing bank setup.
                </div>
              </div>
              <div
                className={`accordion-item ${openIndex === 2 ? 'open' : ''}`}
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="accordion-header">
                  <span className="accordion-title">How can I order equipment?</span>
                  <span className="accordion-icon">{openIndex === 2 ? '-' : '+'}</span>
                </div>
                <div className={`accordion-body ${openIndex === 2 ? 'show' : ''}`}>
                  Equipment orders can be placed directly through your account dashboard.
                </div>
              </div>
              <div
                className={`accordion-item ${openIndex === 3 ? 'open' : ''}`}
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="accordion-header">
                  <span className="accordion-title">Do you offer volume discounts?</span>
                  <span className="accordion-icon">{openIndex === 3 ? '-' : '+'}</span>
                </div>
                <div className={`accordion-body ${openIndex === 3 ? 'show' : ''}`}>
                  Yes, we offer volume discounts based on the quantity ordered.
                </div>
              </div>
              <div
                className={`accordion-item ${openIndex === 4 ? 'open' : ''}`}
                onMouseEnter={() => handleMouseEnter(4)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="accordion-header">
                  <span className="accordion-title">How does signing up work?</span>
                  <span className="accordion-icon">{openIndex === 4 ? '-' : '+'}</span>
                </div>
                <div className={`accordion-body ${openIndex === 4 ? 'show' : ''}`}>
                  Signing up is quick and easy. Just complete the registration form on our website.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
