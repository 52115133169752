import Header from "./components/header/navbar";
import Hero from "./components/hero/hero";
import Companies from "./components/companies/companies";
import Product from "./components/Product/Product";
import Contact  from "./components/contact/contact";
import Getstart  from "./components/Getstarted/getstart";
import Services  from "./components/Services/Services";
import Footer from "./components/footer/footer";
import About from "./components/About/About";
import FAQ from "./components/FAQ/FAQ";
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
  <div className="App">
    <Header/>
    <Hero/>
    <Companies/>
    <Services/>
    <About/>
    <Product/>
    <FAQ/>
    <Contact/>
    <Getstart/>
   
    <Footer/>
    
  </div>
  );
}

export default App;
