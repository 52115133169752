import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "./Product.css";
import data from "../../utils/slider.json";
import { sliderSettings } from "../../utils/common";

const ProductPage = () => {
  return (
    <section className="p-wrapper">
      <div className="padding innerWidth p-container">
        <div className="p-head flexColCenter">
          <span className="tp-section-subtitle-4 pb-10">Top Picks</span>
          <span className="mainText">Featured Products</span>
        </div>
        <div className="p-slider-container">
          <Swiper {...sliderSettings}>
            <SliderButtons />
            {data.map((card, i) => (
              <SwiperSlide key={i}>
                <div className="flexColStart p-card">
                  <img src={card.image} alt={card.name} />
                  <span className="mainText">{card.name}</span>
                  <span className="subText">{card.detail}</span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

const SliderButtons = () => {
  const swiper = useSwiper(); // Ensure useSwiper is correctly imported and used
  return (
    <div className="p-buttons">
      <button onClick={() => swiper.slidePrev()}>&lt;</button>
      <button onClick={() => swiper.slideNext()}>&gt;</button>
    </div>
  );
};

export default ProductPage;
