import { RiMapPinFill } from "react-icons/ri";
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import './footer.css';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2 className="logo-text"><span>Techmetrica </span> Software</h2>
          <p>
            At Techmetrica, we offer a suite of products designed to help businesses of all sizes collaborate and achieve their goals.
          </p>
          <div className="social-icons">
            <a href="#"><FaFacebookF /></a>
            <a href="#"><FaTwitter /></a>
            <a href="#"><FaInstagram /></a>
            <a href="#"><FaLinkedinIn /></a>
          </div>
        </div>
        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">About</a></li>
            <li><a href="#">Services</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h2>Contact</h2>
          <ul>
            <li><FaEnvelope /> <a href="mailto:example@example.com">Email Us</a></li>
            <li><FaPhone /> <a href="tel:+123456789">Call Us</a></li>
            <li>
              <RiMapPinFill className="contact-icon" />
              <a>3rd Floor F5, F Block, Sector 3, Noida, Uttar Pradesh 201301</a>
            </li>
          </ul>
        </div>
        <div className="footer-section map">
          <h2>Find Us</h2>
          <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: '200px', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[51.505, -0.09]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>

      <div className="footer-bottom">
        <p className="cblack">&copy; 2023 Techmetrica Software | Designed by Techmetrica</p>
      </div>
    </footer>
  );
};

export default Footer;
