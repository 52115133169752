import React from 'react';
import './About.css'; // Make sure to create and import your CSS file

const About = () => {
  return (
    <div className="about-area py-5 position-relative" id="about">
      <div className="about-top-img">
        <img src="./about.png" alt="About Background" />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-5 wow fadeInLeft" data-wow-duration=".9s" data-wow-delay=".3s">
            <div className="about-left-img text-md-center position-relative text-start z-index-1 p-0">
              <img src="./about.png" alt="About Image" />
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 wow fadeInRight" data-wow-duration=".9s" data-wow-delay=".5s">
            <div className="about-content">
              <div className="about-content-box mb-4">
                <h5 className="section-subtitle pb-10">---Company Profile</h5>
                <h3 className="section-title pb-25">We Build Great Things Together at Techmetrica</h3>
                <p>
                  At Techmetrica, we're passionate about innovation and teamwork. We use our expertise to help businesses like yours create amazing digital experiences.
                </p>
                <p>
                  Our friendly and knowledgeable staff will work closely with you to understand your needs and develop custom solutions. We believe in the power of clear rules in technology, just like our name suggests!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
