import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div className="tp-platform-area border-tb blue-bg pt-115 pb-105 p-relative z-index" id="services">
      <div className="background-image">
        </div> 
      <div className="container">
        <div className="row align-items-end">
          <div className="col-xl-7 col-lg-6">
            <div className="tp-platform-section-box">
              <h5 className="tp-section-subtitle-4 pb-10">---What we offer</h5>
              <h3 className="tp-section-title-4">Our Services</h3>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".5s">
            <div className="tp-platform-text">
              <p>
                At Techmetrica, we offer a suite of services designed to help businesses of all sizes collaborate and achieve their goals.
              </p>
            </div>
          </div>
        </div>
        <div className="service-wrapper">
          {services.map((service, index) => (
            <div className="service-box" key={index}>
              <img src={service.image} alt={service.title} className="service-img" />
              <div className="service-content">
                <h4 className="service-title">{service.title}</h4>
                <p className="service-description">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const services = [
  {
    title: 'Point of Sales',
    description: 'Use the POS System from Techmetrica to Streamline Your Sales. With the help of our intuitive Point of Sale system, you can easily manage your inventory and transactions.',
    image: './contact.png',
  },
  {
    title: 'School Management',
    description: 'Use the School Management Software from Techmetrica to Run Your School Effectively.',
    image: './contact.png',
  },
  {
    title: 'Office Management',
    description: 'Use the Office Management Software from Techmetrica to Increase Team Productivity.',
    image: './contact.png',
  },
  {
    title: 'College Management',
    description: 'Use the College Management Software from Techmetrica to Simplify Your College Operations.',
    image: './contact.png',
  },
];

export default Services;
