import React from "react";
import "./contact.css";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import { HiChatBubbleBottomCenter } from "react-icons/hi";
import { BsFillChatDotsFill } from "react-icons/bs";
import { RiMapPinFill } from "react-icons/ri";


const Contact = () => {
  return (
    <section className="contact-area" id="contact">
      <div className="contact-overlay">
        <div className="contact-glob-img">
          <img src="./hero-4-3.png" alt="Contact Background" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-12 z-index-3">
              <div className="contact-section-box">
                <h5 className="tp-section-subtitle-4 pb-10">CONTACT US</h5>
                <h3 className="section-title">Request Free Consultancy</h3>
              </div>
              <div className="contact-info-box">
                <ul>
                  <li>
                    <FaPhoneAlt className="contact-icon" />
                    <a href="tel:(+91) 99900 43650">(+91) 9643745101</a>
                  </li>
                  <li>
                    <FaEnvelope className="contact-icon" />
                    <a href="mailto:dummy@gmail.com">varunyadav336@gmail.com</a>
                  </li>
                  <li>
                    <RiMapPinFill className="contact-icon" />
                    <a>3rd Floor F5, F Block, Sector 3, Noida, Uttar Pradesh 201301</a>
                  </li>
                </ul>
              </div>
              <div className="social-links">
                <a href="https://www.facebook.com/tarulesocial" target="_blank" rel="noopener noreferrer" className="social-icon">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/tarulesocial/" target="_blank" rel="noopener noreferrer" className="social-icon">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/company/tarulesocial" target="_blank" rel="noopener noreferrer" className="social-icon">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="https://twitter.com/tarulesocial" target="_blank" rel="noopener noreferrer" className="social-icon">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>
            <div className="col-xl-7 col-12">
              <div className="contact-form-wrapper">
                <form action="/" method="post">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group">
                        <input type="text" name="FirstName" placeholder="Full Name" required />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <input type="text" name="LastName" placeholder="Last Name" required />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <input type="email" name="EmailId" placeholder="Email Address" required />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <input type="tel" name="MobileNo" placeholder="Mobile No." maxLength="10" required />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <select name="ServiceName" required>
                          <option value="" disabled selected>Select Service</option>
                          <option>IT Consultancy</option>
                          <option>APP Development</option>
                          <option>Website Designing</option>
                          <option>Software Development</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <textarea name="Message" placeholder="Enter your message" required></textarea>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <button type="submit" className="submit-button">Get a Free Consultation</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
